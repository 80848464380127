import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Title from 'components/title/title.css';
import Subtitle from 'components/text/subtitle/subtitle.css';
import Head from 'components/head';
import Paragraph from 'components/text/paragraph';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import MEDIA from 'helpers/mediaTemplates';

const HeaderTitle = styled(Title)`
  text-align: center;
  margin-bottom: 4rem;
`;

const StyledTitle = styled(Title)`
  text-align: left;
  margin-bottom: 1rem;
`;

const StyledDate = styled(Subtitle)`
  text-align: right;
`;

const Container = styled.div`
  max-width: 540px;
  margin: 0 auto;

  ${MEDIA.TABLET`
    margin: 0 2rem;
  `};
`;

const ContentContainer = styled.div`
  padding-top: 10rem;

  ${MEDIA.TABLET`
    padding-top: 8rem;
  `};
`;

const NowPage = ({ data }) => (
  <Layout>
    <Head pageTitle={data.nowJson.title} />
    <Container>
      <ContentContainer>
        <HeaderTitle as={'h1'} size={'large'}>
          /now
        </HeaderTitle>
        <StyledTitle>Work</StyledTitle>
        <Paragraph>
          I{'\'m'} based in the garden city of Singapore, currently working at{' '}
          <a
            href={'https://www.tiimhealthcare.com'}
            target="_blank"
            rel="noopener noreferrer"
          >
            TIIM Healthcare
          </a>
          {' '}to make the triage far more efficient in Emergency Departments with
          artificial intelligence. Pretty much looking to advance the state of
          healthcare to a point where machine, patient and doctor can coexist
          with maximum efficiency.
        </Paragraph>
        <StyledTitle>Other Interests</StyledTitle>
        <Paragraph>
          Besides my work, I{'\'m'} interested in topics ranging from{' '}
          <a
            href={
              'https://wiki.lesswrong.com/wiki/Friendly_artificial_intelligence'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            friendly artificial intelligence
          </a>
          , physics, astronomy, and psychology. I love discussing about all
          stuff{' '}
          <a
            href={
              'https://en.wikipedia.org/wiki/Science,_technology,_engineering,_and_mathematics'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            STEM
          </a>{' '}
          and learning new things along the way. Recently, I got certified as an
          Advanced Open Water scuba-diver – so I{'\'m'} looking to do dives and
          explore in new places.
        </Paragraph>
        <StyledDate as={'span'} size={'small'}>
          – 1st June 2019
        </StyledDate>
      </ContentContainer>
    </Container>
  </Layout>
);

NowPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default NowPage;

export const query = graphql`
  query NowpageQuery {
    nowJson {
      title
    }
  }
`;
