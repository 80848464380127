import React from 'react';
import PropTypes from 'prop-types';
import Text from './paragraph.css';

const Paragraph = ({
  children,
  as = 'p',
  size = 'medium',
  color = 'inherit',
}) => {
  return (
    <Text as={as} size={size} color={color}>
      {children}
    </Text>
  );
};

Paragraph.propTypes = {
  children: PropTypes.string.isRequired,
  as: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
};

export default Paragraph;
